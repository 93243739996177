* {
  font-family: Raleway;
}

/* Theme CSS */
.scale-hover {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate(0px) scale(0.1, 1);
    opacity: 0;
    z-index: 1;
    transition: transform 0.3s, opacity 0.3s;
  }

  &:hover::before {
    opacity: 1;
    transform-origin: 50%;
    transform: scale(1);
  }

  &:active::before {
    opacity: 0;
  }
}

// Owner select box
.selector-wrapper {
  position: relative;
  margin-top: -60px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;

  .owner-selector {
    .selector-body select {
      width: 143px;
      height: 44px;
    }
  }
}

// Custom Checkbox
.form-check {
  input[type='radio'] {
    width: 0;
    height: 0;
  }

  * {
    cursor: pointer;
  }

  input[type='radio']::before {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: 12px;
    left: 0;
    position: relative;
    background-color: #e9edf3;
    content: '';
    display: inline-block;
    visibility: visible;
  }

  input[type='radio']:checked::before {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: 12px;
    left: 0;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 6px solid #926997;
  }

  label {
    margin-left: 20px;
  }
}

// Bottom action buttons such as Back, Next
.bottom-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  p {
    position: relative;
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    color: #eb5757;
    margin: 0;
  }

  label.description {
    font-weight: 900;
    font-size: 16px;
    line-height: 14px;
    color: #89868c;
  }

  &.done {
    p {
      color: #27ae60;
    }
  }
}

// Modal Dialog
.custom-modal {
  .modal-content {
    border-radius: 10px;

    .modal-header {
      background: #f7f8f9;
      align-items: center;
      padding: 8px 20px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .modal-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #89868c;
      }

      button {
        padding: 15px;
      }
    }

    .modal-body {
      padding: 25px 30px 0;

      .form-group {
        .outcome-type-group {
          width: 100%;
          margin-bottom: 10px;

          label {
            position: relative;
            height: 35px;
            background: #f7f8f9;
            border: 0;
            border-radius: 4px;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            color: #89868c;
            margin-right: 12px;
            box-shadow: none;
            display: flex;
            justify-content: center;
            align-items: center;

            &.active {
              background: rgba(146, 105, 151, 0.1);
              color: #926997;
            }

            &:not(.active) {
              &:not(.disabled) {
                &::before {
                  background: rgba(146, 105, 151, 0.1);
                  border-radius: 4px;
                  z-index: -1;
                }
              }
            }
          }

          label:last-child {
            margin-right: 0;
          }
        }

        label {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #272429;
        }

        input,
        select {
          background: #ffffff;
          border: 2px solid #e9edf3;
          box-sizing: border-box;
          border-radius: 4px;
          height: 44px;
          padding: 6px 13px;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #45404d;

          &:disabled {
            background: rgba($color: #926997, $alpha: 0.1);
            font-weight: 900;
          }
        }
      }
    }

    .modal-footer {
      border-top: 0;
      padding: 20px 30px 34px;
      display: flex;
      justify-content: flex-start;

      button {
        position: relative;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
      }

      .add,
      .delete {
        width: 200px;
        height: 44px;
        background: #926997;
        border-radius: 4px;
        color: #ffffff;
        z-index: 1;

        &::before {
          background: rgba($color: #ffffff, $alpha: 0.1);
          z-index: -1;
        }
      }

      .delete {
        width: 126px;
      }

      .cancel {
        width: 126px;
        height: 44px;
        color: #89868c;
        border: 1px solid #c1c8d2;
        box-sizing: border-box;
        border-radius: 4px;

        &:hover {
          background: none;
        }

        &::before {
          background: rgba($color: #666768, $alpha: 0.1);
          border-radius: 4px;
        }
      }
    }
  }
}

// Table
.react-bootstrap-table {
  table {
    tr {
      background-color: #ffffff !important;
      padding: 0 15px;

      th,
      td {
        border: 0;
        border-bottom: 1px solid #e9edf3;
        padding: 0 5px;
        vertical-align: middle;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }
    }

    thead {
      tr {
        height: 38px;

        th {
          font-weight: 900;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: #89868c;
        }
      }
    }

    tbody {
      tr {
        height: 58px;

        td {
          font-weight: 900;
          font-size: 14px;
          line-height: 16px;
          color: #89868c;

          &:first-child {
            color: #45404d;
          }

          &.edit-cell,
          &.delete-cell {
            width: 40px;
            padding-left: 0;
          }
        }
      }
    }
  }
}
