.notifications {
  position: absolute;
  bottom: 0;
  right: 0;
  min-height: 200px;
  width: 300px;

  .toast-wrapper {
    z-index: 2000;
    position: absolute;
    min-width: 250px;
    margin: 10px;
    bottom: 0;
    right: 0;
  }
}
